<template>
  <div class="p-3 txt-grey-900 fs-14 fw-500">
    <div class="bg-white rounded-2xl p-3 h-auto overflow-auto">
      <div v-if="hr && hr.person">
        <div class="text-2xl text-black font-bold">{{ hr.person.name }}</div>
        <div class="row space-x-2">
          <div class="col-md-3">
            {{ hr.person.gender && appUtils.getGender(hr.person.gender) }}
            <span v-if="hr.person.address">, {{ hr.person.address }}</span>
          </div>
          <div class="col-md-2">
            {{ hr.person.birthday }}
            <span v-if="hr.person.age">({{ hr.person.age }} {{$t('health_book_page.lbl_age')}})</span>
          </div>
          <div class="col-md-2">{{ hr.person.phone }}</div>
          <div class="col-md-2">{{$t('health_book_page.lbl_insurance_no')}} {{ hr.person.insurance_number }}</div>
          <div class="col-md-2">{{$t('health_book_page.lbl_profession')}} {{ hr.person.career }}</div>
        </div>
      </div>
      <el-divider class="my-2"></el-divider>
      <div class="pt-2 flex align-items-center justify-content-between">
        <div class="flex align-items-center">
          <a
            href="javascript:;"
            @click="goToHR"
            class="fs-14 txt-pri text-decoration-underline mr-1"
          >{{ hr && hr.code }}</a>
          <p class="mb-0 fs-14 txt-pri"><span class="mr-1">></span>{{$t('health_book_page.lbl_prescription')}}</p>
        </div>
        <el-button
          type="primary"
          class="text-sm"
          @click="createPrescription()"
        >{{$t('health_book_page.lbl_add_prescription')}}</el-button>
      </div>
      <el-table
        class="w-full mt-3"
        :data="prescriptions.data"
        @row-click="viewDetail"
        row-class-name="cursor-pointer"
      >
        <!-- <el-table-column label="Mã Sổ khám">
          <template slot-scope="scope">
            <span class="">{{scope.row.code}}</span>
          </template>
        </el-table-column> -->
        <el-table-column :label="$t('health_book_page.lbl_prescription_name')">
          <template slot-scope="scope">
            <span class="">{{ scope.row && scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('health_book_page.lbl_prescription_date')">
          <template slot-scope="scope">
            <span class="">{{ formatDateDMY(scope.row.date) }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('health_book_page.lbl_doctor')">
          <template slot-scope="scope">
            <span class="">{{ scope.row.prescribed_doctor }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('health_book_page.lbl_created_at')">
          <template slot-scope="scope">
            <span class="">{{ formatDateDMY(scope.row.created_at) }}</span>
          </template>
        </el-table-column>
      </el-table>
      <Pagination
        v-show="prescriptions && prescriptions.total"
        :items="prescriptions"
        :current_page="current_page"
        @onRefresh="getPrescriptions"
      ></Pagination>
    </div>

    <ModalAddPresciption
      v-if="hr"
      ref="ModalAddPresciption"
      :person_id="hr.person_id"
      :hr_id="hr.id"
      @refresh="getPrescriptions"
    />
    <ModalViewHR
      :person="hr && hr.person"
      ref="ModalViewHR"
    />
  </div>
</template>
  
<script>
import moment from 'moment'
import Pagination from '@/components/Pagination'
import ModalAddPresciption from './ModalAdd.vue'
import ModalViewHR from './ModalView.vue'
import appUtils from '../../utils/appUtils'

export default {
  components: { Pagination, ModalAddPresciption, ModalViewHR },
  name: 'Prescriptions',
  data () {
    return {
      appUtils,
      hr: null,
      prescriptions: {},
      valueSearch: '',
      current_page: 1,
      params: {
        page: 1
      },
      statuses: [
        { id: 0, name: 'Chờ khám', class: 'text-warning' },
        { id: 1, name: 'Đang khám', class: 'text-warning' },
        { id: 2, name: 'Có kết luận', class: 'text-success' },
        { id: 3, name: 'Đã trả sổ ', class: 'text-danger' }
      ]
    }
  },
  mounted () {
    this.getHealthRecordByID()
    this.getPrescriptions()
    if (this.$route.query.id) {
      this.viewDetailById(this.$route.query.id)
    }
  },
  methods: {
    async getHealthRecordByID () {
      try {
        await this.$rf.getRequest('DoctorRequest').getHealthRecordByID(this.$route.params.id).then(res => {
          this.hr = res.data
        })
      } catch (e) {
        console.log(e)
      }
    },
    async getPrescriptions () {
      try {
        this.current_page = parseInt(this.$route.query.page) || 1
        const param = {
          ...this.params,
          page: this.current_page,
          health_record_id: this.$route.params.id
        }
        if (this.valueSearch) param.search = this.valueSearch
        await this.$rf.getRequest('DoctorRequest').getPrescriptions(param).then(res => {
          this.prescriptions = res.data
        })
      } catch (e) {
        console.log(e)
      }
    },
    formatDateDMY (date) {
      return date && moment(date).format('DD/MM/YYYY')
    },
    getStatus (index) {
      if (!index) return this.statuses[0].name
      return this.statuses[index].name
    },
    createPrescription (obj) {
      this.$refs.ModalAddPresciption.openDialog(obj)
    },
    viewDetail (obj) {
      this.$refs.ModalViewHR.openDialog(obj.id)
    },
    viewDetailById (id) {
      this.$refs.ModalViewHR.openDialog(id)
    },
    goToHR () {
      this.$router.push({ name: 'HealthRecords', query: { id: this.$route.params.id } })
    }
  }
}
</script>
  
<style scoped>
</style>